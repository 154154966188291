import { combineReducers } from "redux";
import countryList from "../reducers/countryReducer";
import geolocation from "../reducers/geolocationReducer";
import hfReducer from "../reducers/hfReducer";

export const rootReducer = combineReducers({
  countryList,
  geolocation,
  hfReducer,
});
