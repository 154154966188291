import "../../assets/css/loader.css";
import logo from "../../assets/images/Polity.png";
export const Loader = () => {
  return (
    <div className="loader-wrapper">
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
      <div className="loader-img">
        <img src={logo} alt="polity-logo" />
      </div>
    </div>
  );
};
