import {
  FETCH_GEO_SUCCESS,
  FETCH_GEO_ERROR,
} from "./../actions/geolocationActions";

const geoReducer = (state = { code: "", error: null }, action) => {
  switch (action.type) {
    case FETCH_GEO_SUCCESS:
      return {
        ...state,
        code: action.code,
      };
    case FETCH_GEO_ERROR:
      return {
        ...state,
        error: action.code,
      };
    default:
      return state;
  }
};

export default geoReducer;
