import Cookies from "js-cookie";

export const FETCH_GEO_SUCCESS = "FETCH_GEO_SUCCESS";
export const FETCH_GEO_ERROR = "FETCH_GEO_ERROR";

export const geoAction = () => {
  return (dispatch) => {
    const code = Cookies.getJSON("geolocation").country_code;
    if (code) {
      dispatch({
        type: FETCH_GEO_SUCCESS,
        code,
      });
    } else {
      dispatch({
        type: FETCH_GEO_ERROR,
        code: "Country Code not found",
      });
    }
  };
};
