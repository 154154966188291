import { Route, Switch } from "react-router-dom";
import { Suspense, useEffect } from "react";
import { RoutingConstants } from "./constants/RoutingConstants";
import { Loader } from "./components/general/Loader";
import { withRouter } from "react-router";
// import ReactGA from "react-ga";
import ReactGA from "react-ga4";

const PolityRoutes = () => {
  const handleScroll = () => {
    var pathName = document.location.pathname;
    var scrollPosition = window.pageYOffset;
    window.scrollY &&
      sessionStorage.setItem(
        "scrollPosition_" + pathName + document.location.search,
        scrollPosition.toString()
      );
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // const TRACKING_ID = "UA-209992865-1";
  // ReactGA.initialize(TRACKING_ID);
  // ReactGA.pageview(window.location.pathname + window.location.search);

  let TRACKING_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
  ReactGA.initialize(TRACKING_ID);
  
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {RoutingConstants.map((route, i) => (
          <Route exact key={i} path={route.path} component={route.component} />
        ))}
      </Switch>
    </Suspense>
  );
};

export default withRouter(PolityRoutes);
