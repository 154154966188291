export const HEADER_FOOTER_URL = "/wp-json/menus/v1/aggregated-hf-menu/home";
export const HOMEPAGE_URL = "/wp-json/home/v1/home-content";
export const MAILING_URL = "https://polity.msgfocus.com/k/Polity/new_subscription";
export const COUNTRY_LIST_URL = "/wp-json/country/v1/list";
export const GEOLOCATION = "/wp-json/country/v1/geolocation";
export const SUBJECT_DETAIL_URL = "/wp-json/subject/v1/header-highlights";
export const GENERIC_PAGE_URL = "/wp-json/wp/v2/pages?slug=";
export const LOAD_MORE_BOOKS = "/wp-json/subject/v1/books";
export const SERIES_DETAIL_URL = "/wp-json/series/v1/header";
export const LOAD_MORE_BOOKS_SERIES = "/wp-json/series/v1/books";
export const BOOK_DETAIL = "/wp-json/book/v1/bookdetail";
export const AUTHOR_BIO_URL = "/wp-json/author/v1/authorbio/";
export const BLOG_PAGE_URL = "/wp-json/wp/v2/pages?slug=blog";
export const AUTHOR_BOOKS_URL = "/wp-json/author/v1/authorbooks/";
export const BLOG_DETAIL_URL = "/wp-json/wp/v2/posts?slug=";
export const VIEW_EVENT_URL = "/wp-json/events/v1/viewEvent/";
export const AUTHOR_BLOG_URL = "/wp-json/author/v1/authorblog/";
export const SEARCH_URL = "/wp-json/search/v1/data/";
export const NEWS_URL = "/wp-json/news/v1/searchnews";
export const LETTER_BASED_AUTHOR_URL = "/wp-json/author/v1/authorlist/";
export const BLOG_OG_URL = "/wp-json/blog/v1/detailpage"
