import { lazy } from "react";

export const RoutingConstants = [
  {
    path: "/",
    component: lazy(() => import("../components/homepage/Homepage")),
  },
  {
    path: "/subjects/",
    component: lazy(() => import("../components/subjects/Subject")),
  },
  {
    path: "/series/",
    component: lazy(() => import("../components/series/Series")),
  },
  {
    path: "/subjectlanding",
    component: lazy(() => import("../components/subject-detail/SubjectDetail")),
  },
  {
    path: "/serieslanding",
    component: lazy(() => import("../components/series-detail/SeriesDetail")),
  },
  {
    path: "/bookdetail",
    component: lazy(() => import("../components/books/BookDetail")),
  },
  {
    path: "/contact/",
    component: lazy(() => import("../components/contact/Contact")),
  },
  {
    path: "/authors/",
    component: lazy(() => import("../components/author/Author")),
  },
  {
    path: "/authorbio",
    component: lazy(() => import("../components/author-bio/AuthorBio")),
  },
  {
    path: "/authorlist",
    component: lazy(() => import("../components/author/AuthorAlphabaticalList")),
  },
  {
    path: "/author-books",
    component: lazy(() => import("../components/author-books/AuthorBooks")),
  },
  {
    path: "/authorblog",
    component: lazy(() => import("../components/author-blog/AuthorBlog")),
  },
  {
    path: "/blog/",
    component: lazy(() => import("../components/blog/Blog")),
  },
  {
    path: "/blog-detail/:blog",
    component: lazy(() => import("../components/blog-detail/BlogDetail")),
  },
  {
    path: "/events/",
    component: lazy(() => import("../components/events/Events")),
  },
  {
    path: "/view-event",
    component: lazy(() => import("../components/view-event/ViewEvent")),
  },
  {
    path: "/sitemap/",
    component: lazy(() => import("../components/sitemap/Sitemap")),
  },
  {
    path: "/search",
    component: lazy(() => import("../components/search/Search")),
  },
  {
    path: "/news/",
    component: lazy(() => import("../components/news/News")),
  },
  {
    path: "/news/:news",
    component: lazy(() => import("../components/news/NewsDetail")),
  },
  {
    path: "/:genericPath/",
    component: lazy(() => import("../components/general/GenericComponent")),
  },
  {
    component: lazy(() => import("../components/not-found/PageNotFound")),
  },
];
