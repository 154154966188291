import AxiosInstance from "../config/AxiosConfig";
export const makeGetRequest = (url, params) => {
  return AxiosInstance.get(url, {
    params,
  });
};

export const makePostRequest = (url, data) => {
  return AxiosInstance.post(url, data);
};
