import PolityRoutes from "./PolityRoutes";
import "./assets/bootstrap/bootstrap.min.css";
import "./assets/css/style.css";
import { useEffect, useState } from "react";
import { HEADER_FOOTER_URL } from "./constants/APIEndpointConstants";
import { fetchGeolocation } from "./Geolocation";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { geoAction } from "./redux/actions/geolocationActions";
import { fetchHFData } from "./redux/actions/hfActions";
// import ReactGA from "react-ga";

const App = () => {
  // const TRACKING_ID = "UA-209992865-1";

  const [response, setResponse] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    // ReactGA.initialize(TRACKING_ID);
    // ReactGA.pageview(window.location.pathname + window.location.search);
    const makeRequest = async () => {
      const cookie =
        Cookies?.getJSON("geolocation")?.country_code ??
        (await fetchGeolocation());
      if (cookie?.country_code || !cookie?.error) {
        dispatch(geoAction());
        dispatch(fetchHFData(HEADER_FOOTER_URL));
      } else {
        setResponse({ error: "Country Code not found" });
      }
    };
    makeRequest();
  }, [dispatch]);
  return <>{response.error ? response?.error : <PolityRoutes />}</>;
};

export default App;
