import Cookies from "js-cookie";
import { GEOLOCATION } from "./constants/APIEndpointConstants";
import { makeGetRequest } from "./rest/RestService";
export const fetchGeolocation = async () => {
  let country;
  let error;
  try {
    country = await makeGetRequest(GEOLOCATION);
    Cookies.set("geolocation", country.data, { expires: 1 });
  } catch (err) {
    console.error(err);
    error = err;
  }
  return { country_code: country?.data?.country_code, error };
};
