import {
  FETCH_COUNTRY_SUCCESS,
  FETCH_COUNTRY_ERROR,
} from "../actions/countryActions";

const initialState = {
  item: [],
  error: null,
};

const contryReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COUNTRY_SUCCESS:
      return {
        ...state,
        item: action.payload,
      };
    case FETCH_COUNTRY_ERROR:
      return {
        ...state,
        error: action.payload,
        item: [],
      };
    default:
      return state;
  }
};

export default contryReducer;
