import { makeGetRequest } from "../../rest/RestService";

export const FETCH_HF_SUCCESS = "FETCH_HF_SUCCESS";
export const FETCH_HF_ERROR = "FETCH_HF_ERROR";

export const fetchHFData = (url) => {
  return (dispatch) => {
    return makeGetRequest(url)
      .then((json) => {
        dispatch(fetchHFSuccess(json.data));
      })
      .catch((error) => {
        dispatch(fetchHFError(error));
      });
  };
};

const fetchHFSuccess = (json) => ({
  type: FETCH_HF_SUCCESS,
  payload: json,
});

const fetchHFError = (error) => ({
  type: FETCH_HF_ERROR,
  payload: error,
});
