import { makeGetRequest } from "../../rest/RestService";

export const FETCH_COUNTRY_SUCCESS = "FETCH_COUNTRY_SUCCESS";
export const FETCH_COUNTRY_ERROR = "FETCH_COUNTRY_ERROR";

export const fetchCountry = (url) => {
  return (dispatch) => {
    return makeGetRequest(url)
      .then((json) => {
        dispatch(fetchCountrySuccess(json.data));
      })
      .catch((error) => {
        dispatch(fetchCountryError(error));
      });
  };
};

const fetchCountrySuccess = (json) => ({
  type: FETCH_COUNTRY_SUCCESS,
  payload: json,
});

const fetchCountryError = (error) => ({
  type: FETCH_COUNTRY_ERROR,
  payload: error,
});
