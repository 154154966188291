import { hydrate, render } from "react-dom";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { createPolityStore } from "./redux/store/Store";
import { Provider } from "react-redux";
import $ from "jquery";
window.jQuery = $;
require("bootstrap");

const store = createPolityStore();

const APP = (<Provider store={store}>
  <BrowserRouter>
    <App />
  </BrowserRouter>
</Provider>);

// ReactDOM.render(
//   <Provider store={store}>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </Provider>,
//   document.getElementById("root")
// );

 
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(APP, rootElement);
} else {
  render(APP, rootElement);
}
